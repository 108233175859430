import styled from 'styled-components'
import Image from '../Image'

type ImageStyle = 'normal' | 'rounded'

interface HeroImage {
  url: string,
  alt: string,
  imageStyle: ImageStyle,
  objectPosition?: string,
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down',
  breakpoint: number,
  dateModified?: string,
}

export default function HeroImage( {
  url,
  alt,
  imageStyle,
  objectPosition = 'bottom left',
  objectFit = 'contain',
  breakpoint,
  dateModified = null,
}: HeroImage ) {
  if ( imageStyle === 'rounded' ) {
    return (
      <StyledRoundedHeroImage 
        className="bx-c-hero-image"
        $breakpoint={ breakpoint }
      >
        <Image
          src={ url }
          alt={ alt }
          dateModified={ dateModified }
          layout="fill"
          objectFit="cover"
        />
      </StyledRoundedHeroImage>
    )
  }

  return (
    <StyledHeroImage 
      className="bx-c-hero-image"
      $breakpoint={ breakpoint }
    >
      <div>
        <Image
          src={ url }
          alt={ alt }
          dateModified={ dateModified }
          layout="fill"
          objectFit={ objectFit }
          objectPosition={ objectPosition }
        />
      </div>
    </StyledHeroImage>
  )
}

const StyledHeroImage = styled.div<{ $breakpoint: number }>`
  position: relative;
  flex: 0 0 45%;

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  @media ( min-width: ${ p => p.$breakpoint + 1 }px ) and ( max-width: 950px ) {
    flex-basis: 40%;
  }

  @media ( max-width: ${ p => p.$breakpoint }px ) {
    flex-basis: 40vw;
    min-height: 75vw;
  }
`

const StyledRoundedHeroImage = styled.div<{ $breakpoint: number }>`
  position: relative;
  overflow: hidden;
  flex: 0 0 calc( 50% - var( --grid-gutter ) );
  margin-left: auto;
  aspect-ratio: 1.3/1;
  border-bottom-left-radius: 100rem;
  border-top-left-radius: 100rem;

  @media ( min-width: ${ p => p.$breakpoint + 1 }px ) and ( max-width: 950px ) {
    flex-basis: 40%;
    height: 65vw;
    border-top-left-radius: 0;
  }

  @media ( max-width: ${ p => p.$breakpoint }px ) {
    height: 65vw;
    margin-left: 0;
    border-radius: 0;
  }
`
