import { ButtonColors } from '../Button'
import Hero from './Hero'

export default function HeroIllustration( {
  title,
  subtitle,
  image,
  objectPosition,
  objectFit = 'contain' as 'contain' | 'cover' | 'fill' | 'none' | 'scale-down',
  imagePosition,
  bgColor,
  containerCSS,
  buttonLabel,
  buttonLink,
  buttonTarget = null,
  buttonColor = 'red' as ButtonColors,
  buttonIcon = null,
  partnerLabel = null,
  partnerLogos = null,
  testimonial = null,
} ) {
  if ( !image ) {
    return null
  }

  return (
    <Hero 
      title={ title }
      subtitle={ subtitle }
      image={ image }
      imageStyle="normal"
      objectPosition={ objectPosition }
      objectFit={ objectFit }
      imagePosition={ imagePosition }
      containerCSS={ containerCSS }
      buttonLabel={ buttonLabel }
      buttonLink={ buttonLink }
      buttonTarget={ buttonTarget }
      buttonColor={ buttonColor }
      buttonIcon={ buttonIcon }
      bgColor={ bgColor }
      partnerLabel={ partnerLabel }
      partnerLogos={ partnerLogos }
      testimonial={ testimonial }
    />
  )
}
