import Button from '../Button'
import ArrowIcon from '../icons/ArrowIcon'

export default function HeroButton( {
  label,
  link,
  target,
  color,
  icon,
} ) {
  return (
    <Button 
      color={ color || 'red' }
      href={ link }
      target={ target }
    >
      <ButtonContents
        label={ label }
        icon={ icon }
      />
    </Button>
  )
}

function ButtonContents( { label, icon } ) {
  switch ( icon ) {
    case 'rightArrow':
      return (
        <>
          <span>{ label }</span>
          <ArrowIcon />
        </>
      )

    default: return label
  }
}
