import styled, { css } from 'styled-components'
import Container from '../layout/Container'
import Background from '../layout/Background'
import Stack, { StackItem } from '../layout/Stack'
import { ButtonColors } from '../Button'
import PartnerLogo from '../PartnerLogo'
import HeroImage from './HeroImage'
import FlexWrap, { FlexWrapItem } from '../layout/FlexWrap'
import FiveStars from '../icons/FiveStars'
import HeroButton from './HeroButton'

export const breakpoint = 750

interface Hero {
  title: string,
  subtitle: string,
  image: {
    url: string,
    alt: string,
    with: number,
    height: number,
    dateModified: string,
  },
  objectPosition: string,
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down',
  imageStyle: 'normal' | 'rounded',
  imagePosition: 'left' | 'right',
  bgColor: string,
  containerCSS: string,
  buttonLabel: string,
  buttonLink: string,
  buttonTarget?: '_self' | '_blank',
  buttonColor: ButtonColors,
  buttonIcon?: 'rightArrow',
  partnerLabel?: string,
  partnerLogos?: Array<{
    id: string,
    url: string,
    alt: string,
    width: number,
    height: number,
  }>
  testimonial: {
    title: string,
    description: string,
  },
}

export default function Hero( {
  title,
  subtitle,
  image,
  objectPosition,
  objectFit = 'contain',
  imageStyle,
  imagePosition,
  bgColor,
  containerCSS,
  buttonLabel,
  buttonLink,
  buttonTarget,
  buttonColor = 'red',
  buttonIcon = null,
  partnerLabel = null,
  partnerLogos = null,
  testimonial = null,
}: Hero ) {
  return (
    <Background 
      $color={ bgColor }
      style={ { overflow: 'hidden' } }
    >
      <Container $containerType="full">
        <StyledHeroGrid 
          $css={ containerCSS }
          $imagePosition={ imagePosition }
        >
          <HeroImage 
            url={ image.url }
            alt={ image.alt }
            dateModified={ image.dateModified }
            imageStyle={ imageStyle }
            objectPosition={ objectPosition || 'bottom left' }
            objectFit={ objectFit }
            breakpoint={ breakpoint }
          />
          <StyledHeroContent className="bx-c-hero-content">
            <Stack
              $flex
              $justifyContent="center"
              $top={ 8 }
              $bottom={ 8 }
              $between={ 2 }
            >
              <h1>{ title }</h1>
              <p>{ subtitle }</p>
              { buttonLink && (
                <StackItem
                  $top={ 2 }
                >
                  <HeroButton
                    link={ buttonLink }
                    label={ buttonLabel }
                    target={ buttonTarget }
                    color={ buttonColor }
                    icon={ buttonIcon }
                  />
                </StackItem>
              ) }
              { ( partnerLogos?.length > 0 || testimonial ) && (
                <StackItem
                  $top={ 2 }
                >
                  <FlexWrap $gap={ 2 }>
                    { partnerLogos?.length > 0 && (
                      <PartnerLogo
                        label={ partnerLabel }
                        logos={ partnerLogos }
                      />
                    ) }
                    { testimonial && (
                    <FlexWrapItem 
                      $flexGrow={ 1 }
                    >
                      <StyledTestimonial className="bx-c-hero-testimonial">
                        <FiveStars />
                        <p>
                          “{ testimonial.description }”
                          <span>~ { testimonial.title }</span>
                        </p>
                      </StyledTestimonial>
                    </FlexWrapItem>
                    ) }
                  </FlexWrap>
                </StackItem>
              ) }
            </Stack>
          </StyledHeroContent>
        </StyledHeroGrid>
      </Container>
    </Background>
  )
}

export const StyledHeroGrid = styled.div<{ 
  $css: string,
  $imagePosition: 'left' | 'right' 
}>`
  display: flex;
  flex-direction: ${ p => p.$imagePosition === 'left' ? 'row' : 'row-reverse' };
  align-items: stretch;
  min-height: min( 40vw, 75vh, 35rem );

  @media ( max-width: ${ breakpoint }px ) {
    flex-direction: column-reverse;
  }

  ${ p => p.$css && css`${ 
    p.$css.replace( 
      /\${\s*(?<key>[a-zA-Z0-9_-]+)\s*}/gm,
      ( _, key ) => key === 'breakpoint' ? `${ breakpoint }` : '',
    ) 
  }` }
`

export const StyledHeroContent = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  padding-left: min( var( --container-margin-width ), calc( 4 * var( --grid-gutter ) ) );
  margin-right: auto;
  color: ${ p => p.theme.colors.black };

  > div {
    max-width: 100%;
  }

  h1 {
    ${ p => p.theme.typo.heroTitle };
    line-height: 1.1;
    /* hyphens: auto; */
  }

  p {
    ${ p => p.theme.typo.heroSubTitle };
  }

  @media ( max-width: ${ breakpoint }px ) {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: var( --container-margin-width );
    padding-right: var( --container-margin-width );
  }
  
  @media ( min-width: 1700px ) {
    padding-left: 0;
  }
`

const StyledTestimonial = styled.div`
  p {
    margin: 0;
    font-size: ${ p => p.theme.typo.sizes.dropped };

    span {
      margin-left: 0.5em;
      font-weight: 600;
      white-space: nowrap;
    }
  }
`
